import React from 'react';
import InkblotLogo from '../../images/avail/logowhite.png';
import ImgCounsellors from '../../images/avail/counsellors.png';
import AvailLogo from '../../images/avail/availlogowhite.png';

const IntroSection = () => (
  <div className="main-intro">
    <div className="main-left">
      <div className="mi-title">
        <h2>Everyone needs someone to talk to.</h2>
        <h3>
          Secure and confidential video counselling anytime, anywhere, with a practitioner matched
          to your needs.
        </h3>
      </div>
      <div className="mi-logos">
        <div>
          <img src={AvailLogo} alt="avail" />
          <div className="mil-bar" />
          <img src={InkblotLogo} alt="inkblot" />
        </div>
      </div>
      <div className="mi-find">
        <a href="https://app.inkblottherapy.com/signup?affiliation=avail">
          <button className="light-blue-gradient-button">FIND MY PRACTITIONER</button>
        </a>
      </div>
    </div>
    <div className="main-right">
      <div className="mi-counsellors">
        <img src={ImgCounsellors} alt="mi-counsellors" />
      </div>
    </div>
  </div>
);

IntroSection.propTypes = {};

export default IntroSection;
