import React from 'react';

const PriceSection = () => (
  <div className="main-price">
    <div className="mp-title">
      <h2>Inkblot is accessible, tailored and secure;</h2>
      <h2>And best of all?
        <span className="mpt-affordable">&nbsp;It’s affordable.</span>
      </h2>
    </div>
    <div className="mpt-container">
      <div className="mp-table">
        <div className="mpt-first">
          <div className="mpt-header mpt-header-first">
            <div className="mpt-contents">
              <div>
                <span className="mpt-hs">First Session</span>
              </div>
            </div>
          </div>
          <div className="mpt-body mpt-body-first">
            <div className="mpt-contents">
              <div>
                <p className="mpt-bs-first">free!</p>
                <p className="mpt-bfs">Until you find the right fit</p>
              </div>
            </div>
          </div>
        </div>
        <div className="mpt-after">
          <div className="mpt-header mpt-header-after">
            <div className="mpt-contents">
              <div>
                <span className="mpt-hs">Every Session After</span>
              </div>
            </div>
          </div>
          <div className="mpt-body mpt-body-after">
            <div className="mpt-contents">
              <div>
                <p className="mpt-bs-after">$37.50</p>
                <p className="mpt-bfs">per 30 minute session</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PriceSection;
