import React from 'react';
import ImgCheck from '../../images/avail/checkmark.png';

const list = [
  'We follow strict Canadian regulations for online health services.',
  'All information is fully encrypted on a regularly audited server in Toronto.',
  'Only you and your counsellor have access to your mental health information.',
  'All counsellors reside in Canada, are highly experienced, and are regulated professionals.',
];

const SecuritySection = () => (
  <div className="main-security">
    <div className="ms-contents">
      <div className="msc-item">
        <h3 className="msc-title">Your security is our top priority</h3>
        <div className="msc-list">
          <ul>
            {list.map((item, i) => (
              <li key={`key-${i + 1}`}>
                <div className="mscl-bullet">
                  <img src={ImgCheck} alt="check" />
                </div>
                <div className="mscl-text">{item}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default SecuritySection;
