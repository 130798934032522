import React from 'react';
import ImgCounsellors from '../../images/avail/counsellors.png';

const MatchSection = () => (
  <div className="main-match">
    <div className="mm-contents">
      <div className="mmc-item">
        <h3 className="mmc-title">Personalized matches</h3>
        <p className="mmc-text">
          Inkblot matches you with counsellors using a unique matching algorithm that takes your
          personal details and preferences into account as well as the personal details and
          strengths of each Inkblot counsellor.
        </p>
      </div>
    </div>
  </div>
);

MatchSection.propTypes = {};

export default MatchSection;
