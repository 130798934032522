import React from 'react';

const JoinSection = () => (
  <div className="main-join">
    <div className="mj-title">
      <h2>Take your first step towards feeling better.</h2>
    </div>
    <div className="mj-join">
      <a href="https://app.inkblottherapy.com/signup?affiliation=avail">
        <button className="btn-pink-white">CREATE MY ACCOUNT</button>
      </a>
    </div>
  </div>
);

export default JoinSection;
